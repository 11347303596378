<template>
  <viewcard--c
    :title="
      $route.params.id > 0
        ? 'Atualizar material pendente'
        : 'Cadastrar Material Pendente'
    "
    :btsave="$route.params.id > 0 ? btedit : btcreate"
    :btback="{}"
    :busy="loading"
    @clicked-save="save"
  >
    <!-- @clicked-delete="confirmDelete" -->
    <b-form @submit.prevent>
      <b-row>
        <b-col xg="6">
          <b-form-group label="Empresa *">
            <v-select
              v-model="companySelected"
              :options="companies"
              :clearable="false"
              autocomplete="off"
              @search="
                (res) => {
                  searchCompany(res);
                }
              "
              :loading="companyLoading"
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  Pesquisando... <em>{{ search }}</em>
                </template>
                <em style="opacity: 0.5" v-else>Digite o Nome</em>
              </template>
            </v-select>
          </b-form-group>

          <b-form-group label="Projeto">
            <v-select
              v-model="projectSelected"
              :options="projects"
              :clearable="true"
              autocomplete="off"
              @search="
                (res) => {
                  searchProject(res);
                }
              "
              :loading="projectLoading"
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  Pesquisando... <em>{{ search }}</em>
                </template>
                <em style="opacity: 0.5" v-else>Digite o Nome</em>
              </template>
            </v-select>
          </b-form-group>

          <b-form-group label="Professor(a) *">
            <v-select
              v-model="teacherSelected"
              :options="teachers"
              :clearable="false"
              autocomplete="off"
              @search="
                (res) => {
                  searchTeacher(res);
                }
              "
              :loading="teacherLoading"
            >
              <template v-slot:no-options="{ search, searching }">
                <template v-if="searching">
                  Pesquisando... <em>{{ search }}</em>
                </template>
                <em style="opacity: 0.5" v-else>Digite o Nome</em>
              </template>
            </v-select>
          </b-form-group>

          <b-form-group label="Prazo *">
            <flat-pickr
              v-model="LimitDate"
              placeholder="Prazo"
              class="form-control"
              :config="config"
            />
          </b-form-group>

          <b-card-text class="mb-0">Completo ?</b-card-text>
          <b-form-checkbox
            class="custom-control-success mt-1"
            name="check-button"
            switch
            v-model="completed"
          >
            <span class="switch-icon-left">
              <feather-icon icon="CheckIcon" />
            </span>
            <span class="switch-icon-right">
              <feather-icon icon="XIcon" />
            </span>
          </b-form-checkbox>
        </b-col>

        <b-col md="6">
          <b-form-group label="Assuntos *">
            <b-input-group
              v-for="(data, index) in recordingMediaSubjectPending"
              :key="index"
              class="mb-1"
            >
              <b-form-input v-model="data.subject" />
              
              <b-input-group-append>
                <b-button
                  variant="danger"
                  :disabled="recordingMediaSubjectPending.length <= 1"
                  @click="delSubject(index)"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </b-input-group-append>
            </b-input-group>
            <b-button @click="addSubject" variant="primary" class="float-right">
              <feather-icon icon="PlusIcon" /> Assunto
            </b-button>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </viewcard--c>
</template>
<script>
import _recordingPendingService from "@/services/audiovisual/recording-pending";
import _recordingMediaPendingSubjectService from "@/services/audiovisual/recording-media-pending-subject-service";
import _projectService from "@/services/projects-service";
import _teacherService from "@/services/teachers-service";
import _companyService from "@/services/company-service";
import { Portuguese } from "flatpickr/dist/l10n/pt.js";
//import pt_br from "vee-validate/dist/locale/pt_BR.json";

export default {
  data() {
    return {
      btedit: {
        permission: `permission.audiovisual.recording.media.pending.edit`,
      },
      btcreate: {
        permission: `permission.audiovisual.recording.media.pending.create`,
      },
      loading: false,
      projectLoading: false,
      teacherLoading: false,
      companyLoading: false,
      companies: [],
      companySelected: { label: null, value: null },
      projects: [],
      projectSelected: { label: null, value: null },
      teachers: [],
      teacherSelected: { label: null, value: null },
      LimitDate: "",
      completed: false,
      recordingMediaSubjectPending: [{limitDate: null, subject: "", recording_media_pending_id: null}],
      recordingMediaSubjectPendingRemoves: [],
      recording_media_pending_id: 0,

      config: {
        dateFormat: "d/m/Y",
        altFormat: "d-m-Y",
        minDate: "today",
        locale: Portuguese,
      },
    };
  },
  // created() {
  //   localize("pt_BR", pt_br);
  // },
  mounted() {
    this.getRecord();
  },
  methods: {    
    getRecord() {
      if (this.$route.params.id > 0) {
        this.loading = true;
        _recordingPendingService
          .findByIdDetails(this.$route.params.id)
          .then((res) => {
            this.teacherSelected = {label: res.content.teacher_name, value: res.content.teacher_id}
            this.companySelected = {label: res.content.company_name, value: res.content.company_id}
            this.projectSelected = res.content.project_id ? {label: res.content.project_name, value: res.content.project_id} : {label: null, value: null}
            this.LimitDate = res.content.limitDate.split('T')[0].split('-').reverse().join('/')
            this.completed = res.content.completed
            
            // this.recordingMediaSubjectPending = subjects
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => (this.loading = false));

        let subjects = [];
        this.loading = true;

        _recordingMediaPendingSubjectService
          .filter(this.$route.params.id)
          .then((res) => {
            
            if(res.content)
            {
              res.content.forEach(element => {
                subjects.push({
                  id: element.id, limitDate: null, 
                  subject: element.subject, 
                  recording_media_pending_id: element.recording_media_pending_id
                })
              })
            }

          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => {this.recordingMediaSubjectPending = subjects,
                        this.loading = false});

      }
    },
    searchCompany(_search) {
      if (_search && _search.length >= 3) {
        if (!this.companyLoading) {
          this.companyLoading = true;
          setTimeout(() => {
            _companyService
              .autoComplete(_search)
              .then((res) => {
                if (res.content && res.content.length > 0) {
                  this.companies = [];
                  res.content.forEach((element) => {
                    this.companies.push({
                      value: element.id,
                      label: element.name,
                    });
                  });
                }
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.companyLoading = false));
          }, 1500);
        }
      }
    },
    searchProject(_search) {
      if (_search && _search.length >= 3) {
        if (!this.projectLoading) {
          this.projectLoading = true;
          setTimeout(() => {
            _projectService
              .autoComplete(_search)
              .then((res) => {
                if (res.content && res.content.length > 0) {
                  this.projects = [];
                  res.content.forEach((element) => {
                    this.projects.push({
                      value: element.id,
                      label: element.name,
                    });
                  });
                }
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.projectLoading = false));
          }, 1500);
        }
      }
    },
    searchTeacher(_search) {
      if (_search && _search.length >= 3) {
        if (!this.teacherLoading) {
          this.teacherLoading = true;
          setTimeout(() => {
            _teacherService
              .autoComplete(_search)
              .then((res) => {
                if (res.content && res.content.length > 0) {
                  this.teachers = [];
                  res.content.forEach((element) => {
                    this.teachers.push({
                      value: element.id,
                      label: element.name,
                    });
                  });
                }
              })
              .catch((error) => this.$utils.toastError("Notificação", error))
              .finally(() => (this.teacherLoading = false));
          }, 1500);
        }
      }
    },
    validate() {
      let test = true
      if(!this.companySelected.value) {
        return false
      }
      if(!this.teacherSelected.value) {
        return false
      }
      if(!this.LimitDate) {
        return false
      }
      this.recordingMediaSubjectPending.forEach(element => {
        if(element.subject == "" || element.subject == " "){
          test = false
        }
      })
      return test ? true : false
    },
    save(){
      if(this.validate()){

        // Atualiza/cria pendência
        const payloadRMP = {data: {
          id: this.$route.params.id,
          teacher_id: this.teacherSelected.value,
          company_id: this.companySelected.value,
          project_id: this.projectSelected ? this.projectSelected.value : null,
          LimitDate: this.LimitDate.split('/').reverse().join('-'),
          completed: this.completed,
        }};        

        const _createOrUpdateRMP =
          this.$route.params.id > 0
            ? _recordingPendingService.update(payloadRMP)
            : _recordingPendingService.create(payloadRMP);

  
        this.loading = true;
        _createOrUpdateRMP
          .then((res) => 
          {
            
            //if(res.content != null)
              this.recording_media_pending_id = res.content.id;

            this.$utils.toast("Notificação", "Registro salvo com sucesso.");
            this.$router.go(-1);
          })
          .catch((error) => this.$utils.toastError("Notificação", error))
          .finally(() => {

              // atualiza/cria assuntos de pendências
              const payloadRMSP = { data: [] };
              this.recordingMediaSubjectPending.forEach( fe =>
              {
                payloadRMSP.data.push({
                  id: fe.id,
                  limitDate: fe.limitDate,
                  subject: fe.subject,
                  recording_media_pending_id: fe.recording_media_pending_id > 0 ? fe.recording_media_pending_id : this.recording_media_pending_id
                });
              });

              payloadRMSP.data.forEach(fe => {

                const _createOrUpdateRMSP =
                  this.$route.params.id > 0
                    ? _recordingMediaPendingSubjectService.update(fe)
                    : _recordingMediaPendingSubjectService.create(fe);
                
                this.loading = true;
                
                _createOrUpdateRMSP
                  .then(() => {
                    this.$utils.toast("Notificação", "Registro salvo com sucesso.");
                    
                  })
                  .catch((error) => this.$utils.toastError("Notificação", error))
                  .finally(() => (this.loading = false));
                });
              
              //Remove assuntos de pendências
              if(this.recordingMediaSubjectPendingRemoves.length > 0)
              {
                this.recordingMediaSubjectPendingRemoves.forEach(fe => {
                
                  this.loading = true;
                    
                  _recordingMediaPendingSubjectService.delete(fe.id)
                    .then(() => {
                      this.$utils.toast("Notificação", "Assunto da pendência removida com sucesso.");
                      //this.$router.go(-1);
                    })
                    .catch((error) => this.$utils.toastError("Notificação", error))
                    .finally(() => (this.loading = false));                  
                });
              }              
            });
      }else{
        this.$utils.toastError("Notificação", "Campos obrigatórios não preenchidos")
      }
    },
    addSubject() {
      this.recordingMediaSubjectPending.push({
        limitDate: null,
        subject: "",
        recording_media_pending_id: this.$route.params.id > 0 ? this.$route.params.id : 0
      });
    },
    delSubject(index) {
      
      if (this.recordingMediaSubjectPending[index].id != null) 
        this.recordingMediaSubjectPendingRemoves.push({id: this.recordingMediaSubjectPending[index].id});
      
      this.recordingMediaSubjectPending.splice(index, 1);
    },
  },
};
</script>